import Vue from 'vue'
import Vuex from 'vuex'
import layout from '@/store/modules/layout.js'
import auth from '@/store/modules/auth.js'
import examination from '@/store/modules/examination'
import planStatExamination from '@/store/modules/planStatExamination'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    layout,
    auth,
    examination,
    planStatExamination
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
})
