const state = {
  examinationGuid: '',
  tenderGuid: '',
  periodGuid: '',
  tenderName: '',
  periodName: '',
  examinationCode: '',
  approvalUserGuid: '',
  approvalStatus: '',
  approvalIdentityGuid: ''
}

const getters = {
  getExaminationGuid: state => {
    return state.examinationGuid
  },
  getTenderGuid: state => {
    return state.tenderGuid
  },
  getPeriodGuid: state => {
    return state.periodGuid
  },
  getTenderName: state => {
    return state.tenderName
  },
  getPeriodName: state => {
    return state.periodName
  },
  getExaminationCode: state => {
    return state.examinationCode
  },
  getApprovalUserGuid: state => {
    return state.approvalUserGuid
  },
  getApprovalStatus: state => {
    return state.approvalStatus
  },
  getApprovalIdentityGuid: state => {
    return state.approvalIdentityGuid
  }
}

const actions = {
}

const mutations = {
  setExaminationGuid (state, examinationGuid) {
    state.examinationGuid = examinationGuid
  },
  setTenderGuid (state, tenderGuid) {
    state.tenderGuid = tenderGuid
  },
  setPeriodGuid (state, periodGuid) {
    state.periodGuid = periodGuid
  },
  setTenderName (state, tenderName) {
    state.tenderName = tenderName
  },
  setPeriodName (state, periodName) {
    state.periodName = periodName
  },
  setExaminationCode (state, examinationCode) {
    state.examinationCode = examinationCode
  },
  setApprovalUserGuid (state, approvalUserGuid) {
    state.approvalUserGuid = approvalUserGuid
  },
  setApprovalStatus (state, approvalStatus) {
    state.approvalStatus = approvalStatus
  },
  setApprovalIdentityGuid (state, approvalIdentityGuid) {
    state.approvalIdentityGuid = approvalIdentityGuid
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
