import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Layout from '../views/Layout.vue'
import auth from '@/common/auth'
import store from '@/store'
import External from '@/views/External'
import Wechat from '@/views/Wechat'
import utility from '@/common/utility'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: '登录',
    component: Login
  },
  {
    path: '/',
    name: 'layout',
    redirect: '/home',
    component: Layout,
    children: [
      {
        path: 'planstat/g07',
        name: '添加整体计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "planstat-g07" */ '../views/PlanStat/MasterPlan/G07')
      },
      {
        path: 'planstat/g08',
        name: '添加年计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "planstat-g08" */ '../views/PlanStat/YearPlan/G08')
      },
      {
        path: 'planstat/g11',
        name: '添加月计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "planstat-g11" */ '../views/PlanStat/MonthPlan/G11')
      },
      {
        path: 'planstat/g01',
        name: '添加月统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "planstat-g01" */ '../views/PlanStat/MonthStat/G01')
      },
      {
        path: 'home',
        name: '首页',
        meta: {
          level: 0
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: 'building',
        name: '建设中',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "building" */ '../views/Building.vue')
      },
      {
        path: 'tenderDisplay',
        name: '标段概况',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "tenderDisplay" */ '../views/TenderDisplay/TenderDisplay')
      },
      {
        path: 'periodDisplay',
        name: '工期概况',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "periodDisplay" */ '../views/PeriodDisplay/PeriodDisplay')
      },
      {
        path: 'yaxueExamination',
        name: '监理检测计量',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "yaxueExamination" */ '../views/ExaminationManage/YaxueExaminationIndex')
      },
      {
        path: 'heyiExamination',
        name: '鹤伊监理检测计量',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "heyiExamination" */ '../views/ExaminationManage/HeyiExaminationIndex')
      },
      {
        path: 'labExamination',
        name: '监理检测计量',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "labExamination" */ '../views/ExaminationManage/LabExaminationIndex')
      },
      {
        path: 'examination',
        name: '工程计量',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "examination" */ '../views/ExaminationManage/ExaminationIndex')
      },
      {
        path: 'farmerExamination',
        name: '申报管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "examination" */ '../views/ExaminationManage/FarmerExaminationIndex')
      },
      {
        path: 'examinationExcelAttachment',
        name: '工程计量(台账)',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "examinationExcelAttachment" */ '../views/ExaminationManage/ExaminationExcelAttachment')
      },
      {
        path: 'examinationEngineering',
        name: '工程计量(工程部)',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "examinationEngineering" */ '../views/ExaminationManage/ExaminationEngineeringIndex')
      },
      {
        path: 'heyiExaminationDetail',
        name: '鹤伊明细申报',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "heyiExaminationDetail" */ '../views/ExaminationManage/HeyiExaminationDetail')
      },
      {
        path: 'yaxueExaminationDetail',
        name: '明细申报',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "heyiExaminationDetail" */ '../views/ExaminationManage/YaxueExaminationDetail')
      },
      {
        path: 'labExaminationDetail',
        name: '明细申报',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "labExaminationDetail" */ '../views/ExaminationManage/LabExaminationDetail')
      },
      {
        path: 'examinationDetail',
        name: '明细申报',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "examinationDetail" */ '../views/ExaminationManage/ExaminationDetail')
      },
      {
        path: 'farmerExaminationDetail',
        name: '明细申报',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "examinationDetail" */ '../views/ExaminationManage/FarmerExaminationDetail')
      },
      {
        path: 'bindPicture',
        name: '绑定草图',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "bindPicture" */ '../views/ExaminationManage/BindPicture')
      },
      {
        path: 'a15AccountListDetail',
        name: '台账计量',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "a15AccountListDetail" */ '../views/ExaminationManage/Tabs/A15AccountListDetail')
      },
      {
        path: 'a15Detail',
        name: '中间计量',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "a15Detail" */ '../views/ExaminationManage/Tabs/A15Detail')
      },
      {
        path: 'a15ChangeDetail',
        name: '合同外计量',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "a15ChangeDetail" */ '../views/ExaminationManage/Tabs/A15ChangeDetail')
      },
      {
        path: 'meterageList',
        name: '清单导入',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "meterageListIndex" */ '../views/MeterageListManage/MeterageListIndex')
      },
      {
        path: 'meterageListDisplay',
        name: '工程量清单',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "meterageListDisplay" */ '../views/MeterageListManage/MeterageListDisplay')
      },
      {
        path: 'authority',
        name: '权限管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "test" */ '../views/AuthorityManage/AuthorityIndex')
      },
      {
        path: 'company',
        name: '公司管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "company" */ '../views/CompanyManage/CompanyIndex')
      },
      {
        path: 'heYiEngineeringList',
        name: '形象进度管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "company" */ '../views/HeYiEngineeringListManage/HeYiEngineeringListIndex')
      },
      {
        path: 'heYiEngineeringListYearPlan',
        name: '形象进度年计划',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "company" */ '../views/HeYiEngineeringListYearPlanManage/HeYiEngineeringListYearPlanIndex')
      },
      {
        path: 'heYiEngineeringListMonthPlan',
        name: '形象进度月计划',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "company" */ '../views/HeYiEngineeringListMonthPlanManage/HeYiEngineeringListMonthPlanIndex')
      },
      {
        path: 'heYiMeterageListDateStat',
        name: '清单日统计',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "company" */ '../views/HeYiMeterageListDateStatManage/HeYiMeterageListDateStatIndex')
      },
      {
        path: 'heYiMeterageListDateStatSummary',
        name: '清单日统计汇总',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "company" */ '../views/HeYiMeterageListDateStatManage/HeYiMeterageListDateStatSummaryIndex')
      },
      {
        path: 'heYiEngineeringListDateStat',
        name: '形象进度日统计',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "company" */ '../views/HeYiEngineeringListDateStatManage/HeYiEngineeringListDateStatIndex')
      },
      {
        path: 'heYiEngineeringListDateStatSummary',
        name: '形象进度日统计汇总',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "company" */ '../views/HeYiEngineeringListDateStatManage/HeYiEngineeringListDateStatSummaryIndex')
      },
      {
        path: 'department',
        name: '部门管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "department" */ '../views/DepartmentManage/DepartmentIndex')
      },
      {
        path: 'planStatFlow',
        name: '计划统计流程管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "planStatFlow" */ '../views/PlanStatFlowManage/PlanStatFlowIndex')
      },
      {
        path: 'flow',
        name: '流程管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "flow" */ '../views/FlowManage/FlowIndex')
      },
      {
        path: 'identity',
        name: '身份管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "identity" */ '../views/IdentityManage/IdentityIndex')
      },
      {
        path: 'period',
        name: '工期管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "period" */ '../views/PeriodManage/PeriodIndex')
      },
      {
        path: 'warningPercentage',
        name: '预警管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "warningPercentage" */ '../views/WarningPercentageManage/WarningPercentageIndex')
      },
      {
        path: 'project',
        name: '项目管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "project" */ '../views/ProjectManage/ProjectIndex')
      },
      {
        path: 'reportTemplate',
        name: '报表模板管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "reportTemplate" */ '../views/ReportTemplateManage/ReportTemplateIndex')
      },
      {
        path: 'report',
        name: '报表管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "report" */ '../views/ReportManage/ReportIndex')
      },
      {
        path: 'reportStat',
        name: '汇总报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "reportStat" */ '../views/ReportManage/ReportStat')
      },
      {
        path: 'a25Config',
        name: 'A25配置',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "a25Config" */ '../views/A25ConfigManage/A25ConfigIndex')
      },
      {
        path: 'a25TenderConfig',
        name: 'A25标段配置',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "a25TenderConfig" */ '../views/A25TenderConfigManage/A25TenderConfigIndex')
      },
      {
        path: 'reportStat',
        name: '汇总报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "reportStat" */ '../views/ReportManage/ReportStat.vue')
      },
      {
        path: 'summaryReportDisplay',
        name: '合同段计量报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "summaryReportDisplay" */ '../views/ReportManage/SummaryReportDisplay')
      },
      {
        path: 'yaxueReportDisplay',
        name: '计量报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "yaxueReportDisplay" */ '../views/ReportManage/YaxueReportDisplay')
      },
      {
        path: 'heyiReportDisplay',
        name: '鹤伊计量报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "heyiReportDisplay" */ '../views/ReportManage/HeyiReportDisplay')
      },
      {
        path: 'labReportDisplay',
        name: '计量报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "labReportDisplay" */ '../views/ReportManage/LabReportDisplay')
      },
      {
        path: 'reportDisplay',
        name: '计量报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "reportDisplay" */ '../views/ReportManage/ReportDisplay')
      },
      {
        path: 'farmerReportDisplay',
        name: '报表管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "reportDisplay" */ '../views/ReportManage/FarmerReportDisplay')
      },
      {
        path: 'lingbaoExportExcel',
        name: '汇总报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "lingbaoExportExcel" */ '../views/ReportManage/LingbaoExportExcel')
      },
      {
        path: 'privateReportDisplay',
        name: '计量汇总报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "privateReportDisplay" */ '../views/ReportManage/PrivateReportDisplay')
      },
      {
        path: 'farmerPrivateReportDisplay',
        name: '汇总管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "privateReportDisplay" */ '../views/ReportManage/FarmerPrivateReportDisplay')
      },
      {
        path: 'planStatReportDisplay',
        name: '计划统计报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "planStatReportDisplay" */ '../views/ReportManage/PlanStatReportDisplay')
      },
      {
        path: 'role',
        name: '角色管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "role" */ '../views/RoleManage/RoleIndex')
      },
      {
        path: 'tender',
        name: '标段管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "tender" */ '../views/TenderManage/TenderIndex')
      },
      {
        path: 'user',
        name: '用户管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "user" */ '../views/UserManage/UserIndex')
      },
      {
        path: 'passwordChange',
        name: '用户密码修改',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "passwordChange" */ '../views/UserManage/PasswordChange')
      },
      {
        path: 'dictionary',
        name: '字典维护',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "dictionary" */ '../views/DictionaryManage/DictionaryIndex')
      },
      {
        path: 'picture',
        name: '计量图库',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "picture" */ '../views/PictureManage/PictureIndex')
      },
      {
        path: 'planStatExamination',
        name: '计划统计',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "planStatExaminationIndex" */ '../views/PlanStatManage/PlanStatExaminationIndex')
      },
      {
        path: 'planStatExaminationGrid',
        name: '计划统计',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "planStatExaminationGrid" */ '../views/PlanStatManage/PlanStatExaminationGrid')
      },
      {
        path: 'masterPlan',
        name: '整体计划',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "masterPlanIndex" */ '../views/PlanStatManage/MasterPlanIndex')
      },
      {
        path: 'tenderYearPlan',
        name: '合同段列表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "tenderYearPlanIndex" */ '../views/PlanStatManage/TenderYearPlanIndex')
      },
      {
        path: 'yearPlan',
        name: '列表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "yearPlanIndex" */ '../views/PlanStatManage/YearPlanIndex')
      },
      {
        path: 'sectionDateStat',
        name: '工区日统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "sectionDateStat" */ '../views/MeterageDateStatManage/SectionDateStat')
      },
      {
        path: 'sectionMonthStat',
        name: '工区月统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "sectionMonthStat" */ '../views/MeterageDateStatManage/SectionMonthStat')
      },
      {
        path: 'tenderMonthStat',
        name: '合同段月统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "tenderMonthStat" */ '../views/MeterageDateStatManage/TenderMonthStat')
      },
      {
        path: 'projectMonthStat',
        name: '项目月统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "projectMonthStat" */ '../views/MeterageDateStatManage/ProjectMonthStat')
      },
      {
        path: 'projectYearStat',
        name: '项目年统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "projectYearStat" */ '../views/MeterageDateStatManage/ProjectYearStat')
      },
      {
        path: 'g07Index',
        name: '整体计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g07Index" */ '../views/PlanStatManage/G07Index')
      },
      {
        path: 'finalG07Index',
        name: '整体计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g07Index" */ '../views/PlanStatManage/FinalG07Index')
      },
      {
        path: 'g07',
        name: '添加整体计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g07" */ '../views/PlanStatManage/G07')
      },
      {
        path: 'finalG07',
        name: '添加整体计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g07" */ '../views/PlanStatManage/FinalG07')
      },
      {
        path: 'g21Index',
        name: '形象进度整体计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g21Index" */ '../views/PlanStatManage/G21Index')
      },
      {
        path: 'g21',
        name: '添加形象进度整体计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g21" */ '../views/PlanStatManage/G21')
      },
      {
        path: 'g22Index',
        name: '材料整体计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g22Index" */ '../views/PlanStatManage/G22Index')
      },
      {
        path: 'g22',
        name: '添加材料整体计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g22" */ '../views/PlanStatManage/G22')
      },
      {
        path: 'g23Index',
        name: '备料整体计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g23Index" */ '../views/PlanStatManage/G23Index')
      },
      {
        path: 'g23',
        name: '添加备料整体计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g23" */ '../views/PlanStatManage/G23')
      },
      {
        path: 'g08Index',
        name: '年计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g08Index" */ '../views/PlanStatManage/G08Index')
      },
      {
        path: 'finalG08Index',
        name: '年计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g08Index" */ '../views/PlanStatManage/FinalG08Index')
      },
      {
        path: 'g08',
        name: '添加年度计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g08" */ '../views/PlanStatManage/G08')
      },
      {
        path: 'finalG08',
        name: '添加年度计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g08" */ '../views/PlanStatManage/FinalG08')
      },
      {
        path: 'g24Index',
        name: '年形象进度计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g24Index" */ '../views/PlanStatManage/G24Index')
      },
      {
        path: 'g24',
        name: '添加年度形象进度计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g24" */ '../views/PlanStatManage/G24')
      },
      {
        path: 'g10Index',
        name: '年材料计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g10Index" */ '../views/PlanStatManage/G10Index')
      },
      {
        path: 'g10',
        name: '添加年度材料计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g10" */ '../views/PlanStatManage/G10')
      },
      {
        path: 'g25Index',
        name: '年材料备料计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g25Index" */ '../views/PlanStatManage/G25Index')
      },
      {
        path: 'g25',
        name: '添加年度材料备料计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g25" */ '../views/PlanStatManage/G25')
      },
      {
        path: 'g11Index',
        name: '月计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g11Index" */ '../views/PlanStatManage/G11Index')
      },
      {
        path: 'finalG11Index',
        name: '月计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g11Index" */ '../views/PlanStatManage/FinalG11Index')
      },
      {
        path: 'g_04Index',
        name: '月计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g_04Index" */ '../views/PlanStatManage/G_04Index')
      },
      {
        path: 'g_00Index',
        name: '年计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g_00Index" */ '../views/PlanStatManage/G_00Index')
      },
      {
        path: 'g_03Index',
        name: '月统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g_03Index" */ '../views/PlanStatManage/G_03Index')
      },
      {
        path: 'g_01Index',
        name: '月统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g_01Index" */ '../views/PlanStatManage/G_01Index')
      },
      {
        path: 'g_02Index',
        name: '月统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g_02Index" */ '../views/PlanStatManage/G_02Index')
      },
      {
        path: 'g11',
        name: '添加月计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g11" */ '../views/PlanStatManage/G11')
      },
      {
        path: 'finalG11',
        name: '添加月计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g11" */ '../views/PlanStatManage/FinalG11')
      },
      {
        path: 'g26Index',
        name: '月形象进度计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g26Index" */ '../views/PlanStatManage/G26Index')
      },
      {
        path: 'g26',
        name: '添加月形象进度计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g26" */ '../views/PlanStatManage/G26')
      },
      {
        path: 'g101Index',
        name: '日进度统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g101Index" */ '../views/PlanStatManage/G101Index')
      },
      {
        path: 'g02Index-date',
        name: '月形象进度统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g02Index-18" */ '../views/PlanStatManage/G02Index-18')
      },
      {
        path: 'g18Index',
        name: '日形象进度统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g18Index" */ '../views/PlanStatManage/G18Index')
      },
      {
        path: 'g181Index',
        name: '日进度统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g181Index" */ '../views/PlanStatManage/G181Index')
      },
      {
        path: 'g181Excel',
        name: '日进度统计Excel',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g181Excel" */ '../views/PlanStatManage/G181Excel')
      },
      {
        path: 'g25TenderStat',
        name: '标段工程材料备料进度年计划汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g25TenderStat" */ '../views/PlanStatManage/Reports/G25TenderStat.vue')
      },
      {
        path: 'g10TenderStat',
        name: '标段工程材料进度年计划汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g10TenderStat" */ '../views/PlanStatManage/Reports/G10TenderStat.vue')
      },
      {
        path: 'g24TenderStat',
        name: '标段工程形象进度年计划汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g24TenderStat" */ '../views/PlanStatManage/Reports/G24TenderStat.vue')
      },
      {
        path: 'g08TenderStat',
        name: '标段工程进度年计划汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g08TenderStat" */ '../views/PlanStatManage/Reports/G08TenderStat.vue')
      },
      {
        path: 'g05TenderStat',
        name: '标段工程进度年统计汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g05TenderStat" */ '../views/PlanStatManage/Reports/G05TenderStat.vue')
      },
      {
        path: 'g14TenderStat',
        name: '标段工程形象进度年统计汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g14TenderStat" */ '../views/PlanStatManage/Reports/G14TenderStat.vue')
      },
      {
        path: 'g16TenderStat',
        name: '标段工程材料进度年统计汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g16TenderStat" */ '../views/PlanStatManage/Reports/G16TenderStat.vue')
      },
      {
        path: 'htdAttachmentIndex',
        name: '合同段计划统计',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g16TenderStat" */ '../views/PlanStatManage/HtdAttachmentIndex.vue')
      },
      {
        path: 'htdYearPlan',
        name: '计划统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g16TenderStat" */ '../views/PlanStatManage/HtdYearPlanIndex.vue')
      },
      {
        path: 'g17TenderStat',
        name: '标段工程材料备料进度年统计汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g17TenderStat" */ '../views/PlanStatManage/Reports/G17TenderStat.vue')
      },
      {
        path: 'g28TenderStat',
        name: '标段工程材料备料月计划汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g28TenderStat" */ '../views/PlanStatManage/Reports/G28TenderStat.vue')
      },
      {
        path: 'g28Detail1TenderStat',
        name: '标段工程材料备料月计划明细表1',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g28Detail1TenderStat" */ '../views/PlanStatManage/Reports/G28Detail1TenderStat.vue')
      },
      {
        path: 'g28Detail2TenderStat',
        name: '标段工程材料备料月计划明细表2',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g28Detail2TenderStat" */ '../views/PlanStatManage/Reports/G28Detail2TenderStat.vue')
      },
      {
        path: 'g27TenderStat',
        name: '标段工程材料月计划汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g27TenderStat" */ '../views/PlanStatManage/Reports/G27TenderStat.vue')
      },
      {
        path: 'g27Detail1TenderStat',
        name: '标段工程材料月计划明细表1',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g27Detail1TenderStat" */ '../views/PlanStatManage/Reports/G27Detail1TenderStat.vue')
      },
      {
        path: 'g27Detail2TenderStat',
        name: '标段工程材料月计划明细表2',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g27Detail2TenderStat" */ '../views/PlanStatManage/Reports/G27Detail2TenderStat.vue')
      },
      {
        path: 'g26TenderStat',
        name: '标段工程形象进度月计划汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g26TenderStat" */ '../views/PlanStatManage/Reports/G26TenderStat.vue')
      },
      {
        path: 'g26Detail1TenderStat',
        name: '标段工程形象进度月计划明细表1',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g26Detail1TenderStat" */ '../views/PlanStatManage/Reports/G26Detail1TenderStat.vue')
      },
      {
        path: 'g26Detail2TenderStat',
        name: '标段工程形象进度月计划明细表2',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g26Detail2TenderStat" */ '../views/PlanStatManage/Reports/G26Detail2TenderStat.vue')
      },
      {
        path: 'g11TenderStat',
        name: '标段工程进度月计划汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g11TenderStat" */ '../views/PlanStatManage/Reports/G11TenderStat.vue')
      },
      {
        path: 'g11Detail1TenderStat',
        name: '标段工程进度月计划明细表1',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g11Detail1TenderStat" */ '../views/PlanStatManage/Reports/G11Detail1TenderStat.vue')
      },
      {
        path: 'g11Detail2TenderStat',
        name: '标段工程进度月计划明细表2',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g11Detail2TenderStat" */ '../views/PlanStatManage/Reports/G11Detail2TenderStat.vue')
      },
      {
        path: 'g13TenderStat',
        name: '标段工程材料备料进度月统计汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g13TenderStat" */ '../views/PlanStatManage/Reports/G13TenderStat.vue')
      },
      {
        path: 'g13Detail1TenderStat',
        name: '标段工程材料备料进度月统计明细表1',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g13Detail1TenderStat" */ '../views/PlanStatManage/Reports/G13Detail1TenderStat.vue')
      },
      {
        path: 'g13Detail2TenderStat',
        name: '标段工程材料备料进度月统计明细表2',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g13Detail2TenderStat" */ '../views/PlanStatManage/Reports/G13Detail2TenderStat.vue')
      },
      {
        path: 'g13Detail3TenderStat',
        name: '标段工程材料备料进度月统计明细表3',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g13Detail3TenderStat" */ '../views/PlanStatManage/Reports/G13Detail3TenderStat.vue')
      },
      {
        path: 'g04TenderStat',
        name: '标段工程材料进度月统计汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g04TenderStat" */ '../views/PlanStatManage/Reports/G04TenderStat.vue')
      },
      {
        path: 'g04Detail1TenderStat',
        name: '标段工程材料进度月统计明细表1',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g04Detail1TenderStat" */ '../views/PlanStatManage/Reports/G04Detail1TenderStat.vue')
      },
      {
        path: 'g04Detail2TenderStat',
        name: '标段工程材料进度月统计明细表2',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g04Detail2TenderStat" */ '../views/PlanStatManage/Reports/G04Detail2TenderStat.vue')
      },
      {
        path: 'g04Detail3TenderStat',
        name: '标段工程材料进度月统计明细表3',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g04Detail3TenderStat" */ '../views/PlanStatManage/Reports/G04Detail3TenderStat.vue')
      },
      {
        path: 'g02TenderStat',
        name: '标段工程形象进度月统计汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g02TenderStat" */ '../views/PlanStatManage/Reports/G02TenderStat.vue')
      },
      {
        path: 'g02Detail1TenderStat',
        name: '标段工程形象进度月统计明细表1',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g02Detail1TenderStat" */ '../views/PlanStatManage/Reports/G02Detail1TenderStat.vue')
      },
      {
        path: 'g02Detail2TenderStat',
        name: '标段工程形象进度月统计明细表2',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g02Detail2TenderStat" */ '../views/PlanStatManage/Reports/G02Detail2TenderStat.vue')
      },
      {
        path: 'g02Detail3TenderStat',
        name: '标段工程形象进度月统计明细表3',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g02Detail3TenderStat" */ '../views/PlanStatManage/Reports/G02Detail3TenderStat.vue')
      },
      {
        path: 'g01TenderStat',
        name: '标段工程进度月统计汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g01TenderStat" */ '../views/PlanStatManage/Reports/G01TenderStat.vue')
      },
      {
        path: 'g01Detail1TenderStat',
        name: '标段工程进度月统计明细表1',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g01Detail1TenderStat" */ '../views/PlanStatManage/Reports/G01Detail1TenderStat.vue')
      },
      {
        path: 'g01Detail2TenderStat',
        name: '标段工程进度月统计明细表2',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g01Detail2TenderStat" */ '../views/PlanStatManage/Reports/G01Detail2TenderStat.vue')
      },
      {
        path: 'g01Detail3TenderStat',
        name: '标段工程进度月统计明细表3',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g01Detail3TenderStat" */ '../views/PlanStatManage/Reports/G01Detail3TenderStat.vue')
      },
      {
        path: 'dateTenderStat',
        name: '标段工程进度日统计汇总',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "dateTenderStat" */ '../views/PlanStatManage/Reports/DateTenderStat.vue')
      },
      {
        path: 'g18TenderStat',
        name: '标段工程进度日统计汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g18TenderStat" */ '../views/PlanStatManage/Reports/G18TenderStat.vue')
      },
      {
        path: 'g181TenderStat1',
        name: '标段进度日统计汇总表1',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g181TenderStat1" */ '../views/PlanStatManage/Reports/G181TenderStat1.vue')
      },
      {
        path: 'g181TenderStat2',
        name: '标段进度日统计汇总表2',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g181TenderStat2" */ '../views/PlanStatManage/Reports/G181TenderStat2.vue')
      },
      {
        path: 'g19DateTenderStat',
        name: '主要材料备料日统计汇总',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g19DateTenderStat" */ '../views/PlanStatManage/Reports/G19DateTenderStat.vue')
      },
      {
        path: 'g19TenderStat',
        name: '主要材料备料日统计汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g19TenderStat" */ '../views/PlanStatManage/Reports/G19TenderStat.vue')
      },
      {
        path: 'g19DateTenderStatSummary',
        name: '主要材料备料日统计汇总1',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g19DateTenderStatSummary" */ '../views/PlanStatManage/Reports/G19DateTenderStatSummary.vue')
      },
      {
        path: 'g19TenderStatSummary',
        name: '主要材料备料日统计汇总表1',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g19TenderStatSummary" */ '../views/PlanStatManage/Reports/G19TenderStatSummary.vue')
      },
      {
        path: 'g20TenderStat1',
        name: '在场主要机械设备日统计汇总表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g20TenderStat1" */ '../views/PlanStatManage/Reports/G20TenderStat1.vue')
      },
      {
        path: 'g20TenderStat2',
        name: '在场主要人员日统计表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "g20TenderStat2" */ '../views/PlanStatManage/Reports/G20TenderStat2.vue')
      },
      {
        path: 'g18',
        name: '添加日形象进度统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g18" */ '../views/PlanStatManage/G18')
      },
      {
        path: 'g02-18',
        name: '添加月形象进度统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g02-18" */ '../views/PlanStatManage/G02-18')
      },
      {
        path: 'g181',
        name: '添加日统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g181" */ '../views/PlanStatManage/G181')
      },
      {
        path: 'g13Index-date',
        name: '月材料备料进度统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g13Index-19" */ '../views/PlanStatManage/G13Index-19')
      },
      {
        path: 'g19Index',
        name: '日材料备料进度统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g19Index" */ '../views/PlanStatManage/G19Index')
      },
      {
        path: 'g13-19',
        name: '添加月材料备料进度统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g13-19" */ '../views/PlanStatManage/G13-19')
      },
      {
        path: 'g19',
        name: '添加日材料备料进度统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g19" */ '../views/PlanStatManage/G19')
      },
      {
        path: 'g27Index',
        name: '月材料进度计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g27Index" */ '../views/PlanStatManage/G27Index')
      },
      {
        path: 'g27',
        name: '添加月材料进度计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g27" */ '../views/PlanStatManage/G27')
      },
      {
        path: 'g28Index',
        name: '月材料备料进度计划',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g28Index" */ '../views/PlanStatManage/G28Index')
      },
      {
        path: 'g28',
        name: '添加月材料备料进度计划',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g28" */ '../views/PlanStatManage/G28')
      },
      {
        path: 'g01Index',
        name: '月统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g01Index" */ '../views/PlanStatManage/G01Index')
      },
      {
        path: 'finalG01Index',
        name: '月统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g01Index" */ '../views/PlanStatManage/FinalG01Index')
      },
      {
        path: 'g01',
        name: '添加月统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g01" */ '../views/PlanStatManage/G01')
      },
      {
        path: 'finalG01',
        name: '添加月统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g01" */ '../views/PlanStatManage/FinalG01')
      },
      {
        path: 'g02Index',
        name: '月形象进度统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g02Index" */ '../views/PlanStatManage/G02Index')
      },
      {
        path: 'g02',
        name: '添加月形象进度统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g02" */ '../views/PlanStatManage/G02')
      },
      {
        path: 'g04Index',
        name: '月材料进度统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g04Index" */ '../views/PlanStatManage/G04Index')
      },
      {
        path: 'g04',
        name: '添加月材料进度统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g04" */ '../views/PlanStatManage/G04')
      },
      {
        path: 'g13Index',
        name: '月材料备料进度统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g13Index" */ '../views/PlanStatManage/G13Index')
      },
      {
        path: 'g13',
        name: '添加月材料备料进度统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g13" */ '../views/PlanStatManage/G13')
      },
      {
        path: 'g05Index',
        name: '年统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g05Index" */ '../views/PlanStatManage/G05Index')
      },
      {
        path: 'g14Index',
        name: '年形象进度统计',
        meta: {
          level: 4
        },
        component: () => import(/* webpackChunkName: "g14Index" */ '../views/PlanStatManage/G14Index')
      },
      {
        path: 'g16Index',
        name: '年材料进度统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g16Index" */ '../views/PlanStatManage/G16Index')
      },
      {
        path: 'g17Index',
        name: '年材料备料进度统计',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "g17Index" */ '../views/PlanStatManage/G17Index')
      },
      {
        path: 'financialEstimate',
        name: '概算管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "financialEstimateIndex" */ '../views/FinancialEstimateManage/FinancialEstimateIndex.vue')
      },
      {
        path: 'financialEstimateRule',
        name: '概算映射管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "financialEstimateRuleIndex" */ '../views/FinancialEstimateRuleManage/FinancialEstimateRuleIndex.vue')
      },
      {
        path: 'financialEstimateRuleFormNew',
        name: '添加概算映射',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "financialEstimateRuleForm" */ '../views/FinancialEstimateRuleManage/FinancialEstimateRuleForm.vue')
      },
      {
        path: 'financialEstimateRuleForm',
        name: '添加概算映射',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "financialEstimateRuleFormNew" */ '../views/FinancialEstimateRuleManage/FinancialEstimateRuleFormNew.vue')
      },
      {
        path: 'financialEstimateRuleSummary',
        name: '概算映射汇总',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "financialEstimateRuleSummary" */ '../views/FinancialEstimateRuleManage/FinancialEstimateRuleSummary.vue')
      },
      {
        path: 'financialEstimateRuleTenderSummary',
        name: '概算映射明细',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "financialEstimateRuleTenderSummary" */ '../views/FinancialEstimateRuleManage/FinancialEstimateRuleTenderSummary.vue')
      },
      {
        path: 'engineeringList',
        name: '形象进度清单',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "engineeringListIndex" */ '../views/EngineeringListManage/EngineeringListIndex.vue')
      },
      {
        path: 'materialList',
        name: '材料清单',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "materialListIndex" */ '../views/MaterialListManage/MaterialListIndex.vue')
      },
      {
        path: 'prepareMaterialList',
        name: '材料备料清单',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "prepareMaterialListIndex" */ '../views/PrepareMaterialListManage/PrepareMaterialListIndex.vue')
      },
      {
        path: 'log',
        name: '日志查询',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "logIndex" */ '../views/LogManage/LogIndex.vue')
      },
      {
        path: 'changeDirective',
        name: '变更令管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "changeDirective" */ '../views/ChangeDirectiveManage/ChangeDirectiveIndex')
      },
      {
        path: 'changeDirectiveItem',
        name: '变更明细',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "changeDirectiveItem" */ '../views/ChangeDirectiveManage/ChangeDirectiveItemIndex')
      },
      {
        path: 'changeDirectiveItemDetail',
        name: '变更明细录入',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "changeDirectiveItemDetail" */ '../views/ChangeDirectiveManage/ChangeDirectiveItemDetail')
      },
      {
        path: 'changeDirectiveDisplay',
        name: '变更令查看',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "changeDirectiveDisplay" */ '../views/ChangeDirectiveManage/ChangeDirectiveDisplay')
      },
      {
        path: 'changeDirectiveItemDisplay',
        name: '变更明细',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "changeDirectiveItemDisplay" */ '../views/ChangeDirectiveManage/ChangeDirectiveItemDisplay')
      },
      {
        path: 'questions',
        name: '在线问答',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "questions" */ '../views/Questions')
      },
      {
        path: 'material',
        name: '材料名称管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "materialIndex" */ '../views/PlanStatManage/MaterialIndex')
      },
      {
        path: 'superviseFlow',
        name: '监理计量流程管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "superviseFlow" */ '../views/SuperviseMeterage/SuperviseFlow.vue')
      },
      {
        path: 'supervisePeriod',
        name: '监理计量工期管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "superviseFlow" */ '../views/SuperviseMeterage/SupervisePeriod.vue')
      },
      {
        path: 'superviseExamination',
        name: '监理计量管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "superviseFlow" */ '../views/SuperviseMeterage/SuperviseExamination.vue')
      },
      {
        path: 'designExamination',
        name: '设计计量管理',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "designFlow" */ '../views/SuperviseMeterage/DesignExamination.vue')
      },
      {
        path: 'superviseReport',
        name: '监理计量报表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "superviseFlow" */ '../views/SuperviseMeterage/SuperviseReport.vue')
      },
      {
        path: 'designReport',
        name: '设计计量报表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "designFlow" */ '../views/SuperviseMeterage/DesignReport.vue')
      },
      {
        path: 'superviseExaminationRecord',
        name: '监理计量明细管理',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "superviseFlow" */ '../views/SuperviseMeterage/SuperviseExaminationRecord.vue')
      },
      {
        path: 'superviseOtherRecord',
        name: '监理计量其他管理',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "superviseFlow" */ '../views/SuperviseMeterage/SuperviseOtherRecord.vue')
      },
      {
        path: 'superviseExaminationRecordExcel',
        name: '录入监理计量',
        meta: {
          level: 3
        },
        component: () => import(/* webpackChunkName: "superviseFlow" */ '../views/SuperviseMeterage/SuperviseExaminationRecordExcel.vue')
      },
      {
        path: 'commence',
        name: '开工预付款',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "superviseFlow" */ '../views/Commence.vue')
      },
      {
        path: 'tiefangDateStat',
        name: '凤方日统计',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "TieFangDateStat" */ '../views/TieFangDateStat.vue')
      }
    ]
  },
  {
    path: '/external',
    name: 'external',
    redirect: '/external/home',
    component: External,
    children: [
      {
        path: 'home',
        name: '首页',
        meta: {
          level: 0
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: 'report',
        name: '计量报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "reportDisplay" */ '../views/ReportManage/ReportDisplay')
      },
      {
        path: 'yearPlan',
        name: '列表',
        meta: {
          level: 2
        },
        component: () => import(/* webpackChunkName: "yearPlanIndex" */ '../views/PlanStatManage/ExternalYearPlanIndex')
      },
      {
        path: 'planStatExaminationGrid',
        name: '计划统计',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "planStatExaminationGrid" */ '../views/PlanStatManage/PlanStatExaminationGrid')
      }
    ]
  },
  {
    path: '/spreadDemo',
    name: 'spreadDemo',
    meta: {
      level: 1
    },
    component: () => import(/* webpackChunkName: 'spreadDemo' */ '../views/Test/SpreadJs.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test/Test.vue'),
    children: [
      {
        path: 'timeLine',
        name: 'timeLine',
        component: () => import(/* webpackChunkName: "timeLine" */ '../views/Test/TimeLine.vue')
      },
      {
        path: 'demo',
        name: 'demo',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Test/Demo.vue')
      },
      {
        path: 'table',
        name: 'table',
        component: () => import(/* webpackChunkName: "table" */ '../views/Test/Table.vue')
      },
      {
        path: 'cal',
        name: 'cal',
        component: () => import(/* webpackChunkName: "cal" */ '../views/Test/Cal.vue')
      },
      {
        path: 'report',
        name: 'report',
        component: () => import(/* webpackChunkName: "report" */ '../views/Test/Report.vue')
      },
      {
        path: 'excel',
        name: 'excel',
        component: () => import(/* webpackChunkName: "excel" */ '../views/Test/Excel.vue')
      }
    ]
  },
  {
    path: '/wechat',
    name: 'wechat',
    redirect: '/wechat/home',
    component: Wechat,
    children: [
      {
        path: 'home',
        name: '首页',
        meta: {
          level: 0
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: 'antiFork',
        name: '计量报表',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "antiFork" */ '../views/Wechat/AntiFork')
      },
      {
        path: 'rebateCommencement',
        name: '回扣开工预付款',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "rebateCommencement" */ '../views/Wechat/RebateCommencement')
      },
      {
        path: 'rebateMaterial',
        name: '回扣材料预付款',
        meta: {
          level: 1
        },
        component: () => import(/* webpackChunkName: "rebateMaterial" */ '../views/Wechat/RebateMaterial')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!auth.isLogin()) {
    if (utility.startWith(to.path, '/external') || utility.startWith(to.path, '/wechat')) {
      next()
    } else if (to.path === '/login') {
      next()
    } else if (to.path !== '/login') {
      next('/login')
    }
  } else {
    if (to.path === from.path) {
      return
    }
    if (to.path !== '/login') {
      store.commit('layout/activateBreadcrumbItem', { name: to.name, path: to.path, level: to.meta.level })
    }
    if (auth.getUserInfo().poorPassword && to.path !== '/passwordChange' && !utility.startWith(to.path, '/external')) {
      next('/passwordChange')
    } else {
      next()
    }
  }
})

export default router
