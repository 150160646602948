import uuid from 'uuid/v1'
import md5 from 'js-md5'
import config from '@/config'

const utility = {
  startWith (str, startStr) {
    const index = str.indexOf(startStr)
    if (index === 0) {
      return true
    }
    return false
  },

  isEmptyString (val) {
    if (val !== undefined && val !== null && val !== '') {
      return true
    }
    return false
  },

  isNumber (val) {
    if (val !== undefined && val != null && val !== '' && parseFloat(val).toString() !== 'NaN') {
      return true
    }
    return false
  },

  floatAdd (f1, f2) {
    f1 = parseFloat(f1)
    f2 = parseFloat(f2)
    let r1, r2
    try { r1 = f1.toString().split('.')[1].length } catch (e) { r1 = 0 }
    try { r2 = f2.toString().split('.')[1].length } catch (e) { r2 = 0 }
    let m = Math.pow(10, Math.max(r1, r2))
    return (f1 * m + f2 * m) / m
  },

  floatSubstract (f1, f2) {
    f1 = parseFloat(f1)
    f2 = parseFloat(f2)
    let r1, r2
    try { r1 = f1.toString().split('.')[1].length } catch (e) { r1 = 0 }
    try { r2 = f2.toString().split('.')[1].length } catch (e) { r2 = 0 }
    let m = Math.pow(10, Math.max(r1, r2))
    let n = (r1 > r2) ? r1 : r2
    return ((f1 * m - f2 * m) / m).toFixed(n)
  },

  floatMultiple (f1, f2) {
    f1 = parseFloat(f1)
    f2 = parseFloat(f2)
    let m = 0
    try { m += f1.toString().split('.')[1].length } catch (e) {}
    try { m += f2.toString().split('.')[1].length } catch (e) {}
    return Number(f1.toString().replace('.', '')) * Number(f2.toString().replace('.', '')) / Math.pow(10, m)
  },

  floatDivide (f1, f2) {
    f1 = parseFloat(f1)
    f2 = parseFloat(f2)
    let t1 = 0
    let t2 = 0
    let r1, r2
    try { t1 = f1.toString().split('.')[1].length } catch (e) {}
    try { t2 = f2.toString().split('.')[1].length } catch (e) {}
    r1 = Number(f1.toString().replace('.', ''))
    r2 = Number(f2.toString().replace('.', ''))
    return (r1 / r2) * Math.pow(10, t2 - t1)
  },

  trim (str, letter = ' ') {
    str = str.toString()
    return utility.ltrim(utility.rtrim(str, letter), letter)
  },

  ltrim (str, letter = ' ') {
    str = str.toString()
    while (str !== '' && str.charAt(0) === letter) {
      str = str.substring(1, str.length)
    }
    return str
  },

  rtrim (str, letter = ' ') {
    str = str.toString()
    while (str !== '' && str.charAt(str.length - 1) === letter) {
      str = str.substring(0, str.length - 1)
    }
    return str
  },

  padStart (str, num, letter = '0') {
    str = str.toString()
    if (num > str.length) {
      let letterLen = num - str.length
      str = new Array(letterLen).fill(letter).join('') + str
    }
    return str
  },

  getUuid () {
    return uuid()
  },

  getMd5 (str) {
    return md5(str)
  },

  getFullUrl (...partialUrls) {
    return utility.rtrim(config.serviceHost, '/') +
      partialUrls.map(partialUrl => {
        partialUrl = utility.trim(partialUrl, '/')
        return !partialUrl ? '' : '/' + partialUrl
      }).join('')
  },

  getRestFullUrl (...partialUrls) {
    return utility.rtrim(config.restHost, '/') +
      partialUrls.map(partialUrl => {
        partialUrl = utility.trim(partialUrl, '/')
        return !partialUrl ? '' : '/' + partialUrl
      }).join('')
  },

  getCurrentTimestamp () {
    return Math.round(new Date() / 1000)
  },

  getCurrentTime (type = 24) {
    let currentTime = new Date()
    let year = currentTime.getFullYear()
    let month = utility.padStart(currentTime.getMonth() + 1, 2)
    let date = utility.padStart(currentTime.getDate(), 2)
    let hours = utility.padStart(currentTime.getHours(), 2)
    let minutes = utility.padStart(currentTime.getMinutes(), 2)
    let seconds = utility.padStart(currentTime.getSeconds(), 2)

    switch (type) {
      case 8:
        return `${year}${month}${date}`
      case 10:
        return `${year}-${month}-${date}`
      case 14:
        return `${year}${month}${date}${hours}${minutes}${seconds}`
      case 19:
        return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`
      case 24:
        return currentTime
    }
  },

  formatTime (_time, type = 19) {
    let _date = new Date(_time)

    let year = _date.getFullYear()
    let month = utility.padStart(_date.getMonth() + 1, 2)
    let date = utility.padStart(_date.getDate(), 2)
    let hours = utility.padStart(_date.getHours(), 2)
    let minutes = utility.padStart(_date.getMinutes(), 2)
    let seconds = utility.padStart(_date.getSeconds(), 2)

    switch (type) {
      case 0:
        return Math.round(_date / 1000)
      case 8:
        return `${year}${month}${date}`
      case 10:
        return `${year}-${month}-${date}`
      case 19:
        return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`
    }
  },

  getAuthHeader (token, method = 'Bearer') {
    return method + ' ' + token
  },

  getFormData (data) {
    let formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }

    return formData
  }

}

export default utility
