<template>
  <div class="external container-fluid p-3">
    <router-view v-if="isLogin"></router-view>
  </div>
</template>

<script>
import authService from '@/services/authService'
import UserInfo from '@/support/UserInfo'
import auth from '@/common/auth'
import md5 from 'js-md5'

export default {
  name: 'External',
  data () {
    return {
      isLogin: false
    }
  },
  methods: {
    login () {
      this.$myLoading()
      const mobile = this.$route.query.mobile
      const appID = this.$route.query.appID
      let token = this.$route.query.token
      const from = this.$route.query.from
      if (!mobile || !appID || (!token && from !== 'bada')) {
        this.$message({
          type: 'error',
          message: '认证信息不完整'
        })
        return
      }

      if (from === 'bada') {
        token = md5(`${mobile}BF1FB1D4-D796-4DC5-8F11-EE28968E89D1`)
      }

      authService.login(`${mobile}$0$${appID}`, token, '', '')
        .then(res => {
          auth.setToken(res.data.access_token)

          authService.getUserInfo()
            .then(res => {
              auth.setUserInfo(new UserInfo(res.data))

              this.$nextTick(() => {
                this.isLogin = true
                this.$loading().close()
              })
            })
            .catch(err => {
              console.log(err.request.response)
            })
        })
        .catch(err => {
          console.log(err.request.response)
          this.$message({
            type: 'error',
            message: JSON.parse(err.request.response).error_description ? JSON.parse(err.request.response).error_description : '登录失败！'
          })
        })
    }
  },
  created () {
    auth.logout()
    this.$nextTick(() => {
      this.login()
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/custom.scss';

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.external {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
