import Vue from 'vue'

const state = {
  breadcrumbItems: [
    {
      name: '首页',
      path: '/home',
      active: true
    }
  ],
  menuExtended: true
}

const getters = {
  getBreadcrumbItems: state => {
    return state.breadcrumbItems
  },
  getMenuExtended: state => {
    return state.menuExtended
  }
}

const actions = {
}

const mutations = {
  clearBreadcrumbItems (state) {
    state.breadcrumbItems.splice(1, state.breadcrumbItems.length)
  },
  activateBreadcrumbItem (state, item) {
    state.breadcrumbItems.splice(item.level, state.breadcrumbItems.length)
    state.breadcrumbItems.forEach(breadcrumbItem => Vue.set(breadcrumbItem, 'active', false))
    item.active = true
    state.breadcrumbItems.push(item)
  },
  setMenuExtended (state, menuExtended) {
    state.menuExtended = menuExtended
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
