import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

const listPageUrl = 'ListPage'

class BaseService {
  constructor (resourceName) {
    this.resourceName = resourceName
  }

  list (pageInfo, searchModel) {
    if (pageInfo) {
      let params = {}
      Object.assign(params, pageInfo, searchModel)
      return axios.get(utility.getFullUrl(this.resourceName, listPageUrl), {
        params: params,
        headers: {
          'Authorization': utility.getAuthHeader(auth.getToken())
        }
      })
    }
    let params = {}
    Object.assign(params, searchModel)
    return axios.get(utility.getFullUrl(this.resourceName), {
      params,
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  get (resourceId) {
    return axios.get(utility.getFullUrl(this.resourceName, resourceId), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  add (resource) {
    resource.generatePrimaryKey()
    return axios.post(utility.getFullUrl(this.resourceName), resource, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  edit (resource) {
    return axios.put(utility.getFullUrl(this.resourceName), resource, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  delete (resourceId) {
    return axios.delete(utility.getFullUrl(this.resourceName, resourceId), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

export default BaseService
