import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

import '@/assets/scss/iconfont.css'
import '@/assets/icons/css/all.min.css'

Vue.config.productionTip = false
Vue.use(ElementUI)

Vue.use(VueAwesomeSwiper)

Vue.use(ElementUI.Scrollbar)

Vue.prototype.$myLoading = () => {
  Vue.prototype.$loading({
    lock: true,
    text: '拼命加载中...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, .7)'
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
