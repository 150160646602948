<template>
  <div class="container menu-wrapper bg-white p-0 overflow-auto" @mouseover="menuHovered = true" @mouseleave="menuHovered = false" :class="[{ 'extended' : menuExtended }, { 'hover' : menuHovered }]">
    <el-scrollbar style="height: 100%;">
      <nav class="nav brand border-bottom bg-light pl-2">
        <a class="d-flex align-items-center pl-2 text-decoration-none" href="#">
          <img src="@/assets/images/logo.png" class="logo rounded-circle m-2">
          <transition name="fade">
            <p class="logo-title mb-0 text-nowrap text-dark" v-if="menuExtended || menuHovered">
              <span class="pl-2">工程管理云平台</span>
            </p>
          </transition>
        </a>
      </nav>
      <nav class="mt-2 pl-2 pr-2">
        <ul class="nav nav-pills flex-column">
          <li v-for="menu in menus" :key="menu.id" class="nav-item mt-1">
            <a class="nav-link bg-info d-flex" href="#" @click="handleMainMenuClick(menu)">
              <i class="avatar rounded-circle text-light" style="font-size: 16px;" :class="menu.iconClass"></i>
              <transition name="fade">
                <p class="title mb-0 text-nowrap text-white" v-if="menuExtended || menuHovered">
                  <span class="pl-2">{{ menu.authorityName }}</span>
                </p>
              </transition>
            </a>
            <ul v-if="menu.children && menu.children.length" class="nav nav-pills flex-column">
              <li v-for="secondaryMenu in menu.children" :key="secondaryMenu.id" class="nav-item mt-1">
                <a class="my-nav-item nav-link bg-white d-flex" href="#" @click="handleSecondaryMenuClick(secondaryMenu)">
                  <i class="avatar rounded-circle text-dark" style="font-size: 16px;" :class="secondaryMenu.iconClass"></i>
                  <transition name="fade">
                    <p class="title mb-0 text-nowrap text-dark flex-grow-1 position-relative" v-if="menuExtended || menuHovered">
                      <span class="pl-2">{{ secondaryMenu.authorityName }}</span>
                      <img src="@/assets/images/menu-open.png" class="open rounded-circle" :class="{ 'opened' : secondaryMenu.opened }">
                    </p>
                  </transition>
                </a>
                <transition name="open">
                  <ul v-show="secondaryMenu.opened && secondaryMenu.children && secondaryMenu.children.length" class="nav nav-pills flex-column">
                    <li v-for="subMenu in secondaryMenu.children" :key="subMenu.id" class="nav-item mt-1 w-100" @click="handleSubMenuClick(subMenu)">
                      <a class="my-nav-item nav-link bg-white d-flex" :class="{ 'active' : subMenu.authorityId === activeAuthorityId }" href="#">
                        <i class="avatar rounded-circle text-dark" style="font-size: 16px;" :class="subMenu.iconClass"></i>
                        <transition name="fade">
                          <p class="title mb-0 text-nowrap text-dark" v-if="menuExtended || menuHovered">
                            <span class="pl-2">{{ subMenu.authorityName }}</span>
                          </p>
                        </transition>
                      </a>
                    </li>
                  </ul>
                </transition>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import authorityService from '@/services/authorityService'
import utility from '@/common/utility'

export default {
  name: 'MyMenu',
  data () {
    return {
      activeAuthorityId: 0,
      menus: [],
      menuHovered: false
    }
  },
  methods: {
    handleMainMenuClick (menu) {
      if (menu.targetUrl && menu.targetUrl !== '#') {
        this.$router.push(menu.targetUrl)
      }
    },
    handleSubMenuClick (menu) {
      this.activeAuthorityId = menu.authorityId
      menu.targetUrl && utility.trim(menu.targetUrl, '/') !== utility.trim(this.$route.path, '/') && this.$router.push(menu.targetUrl)
    },
    handleSecondaryMenuClick (secondaryMenu) {
      secondaryMenu.opened = !secondaryMenu.opened
    },
    _getAuthorityList () {
      authorityService.treeListByUser()
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.data.length
            res.data.data.forEach(menus => {
              menus.children && menus.children.forEach(secondaryMenu => {
                secondaryMenu.opened = false
                secondaryMenu.children && secondaryMenu.children.forEach(subMenu => {
                  subMenu.active = false
                })
              })
            })
            this.menus = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  computed: {
    ...mapGetters('layout', {
      menuExtended: 'getMenuExtended'
    })
  },
  created () {
    this._getAuthorityList()
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variable.scss';

.menu-wrapper {
  position: absolute;
  z-index: $my-zindex-fixed;
  transition: width .3s;
  width: $menu-shrinked-width;
  height: 100%;
  box-shadow: 0 14px 28px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.22);
}
.menu-wrapper.hover {
  width: $menu-extended-width;
}
.menu-wrapper.extended {
  width: $menu-extended-width;
}
.logo {
  height: 30px;
  box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
}
.avatar {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 25px;
}
.title {
  height: 24px;
  line-height: 24px;
}
.logo-title {
  font-size: 20px;
  height: 30px;
  line-height: 30px;
}
.brand {
  height: $header-height;
  line-height: $header-height;
}
.brand > a {
  height: $header-height;
  line-height: $header-height;
}
img.open {
  position: absolute;
  top: 2px;
  right: 0;
  height: 20px;
  transition: transform .1s;
}
img.open.opened {
  transform: rotate(-90deg);
}
.my-nav-item.active {
  background-color: #eee !important;
}
.my-nav-item:hover {
  background-color: #eee !important;
}
.nav-link {
  height: 40px !important;
  line-height: 40px !important;
}

.open-enter-active {
  max-height: 500px;
  transition: opacity .3s, max-height .2s;
}
.open-leave-active {
  max-height: 500px;
  transition: opacity .1s, max-height .2s;
}
.open-enter, .open-leave-to {
  max-height: 0;
  opacity: 0;
}
.fade-enter-active {
  width: 100px;
  transition: opacity .3s, width .2s;
}
.fade-leave-active {
  width: 100px;
  transition: opacity .1s, width .2s;
}
.fade-enter, .fade-leave-to {
  width: 0;
  opacity: 0;
}
</style>
