<template>
  <div class="login-page">
    <a href=""></a>
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide
        v-for="slide in slides"
        :key="slide.id"
        >
        <div :style="swiperSlideStyle">
          <img class="slide-image" :src="slide.imageUrl" alt="">
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="slogan">
      <img src="@/assets/images/login/slogan.jpg" alt="">
    </div>
    <div @click="changeMode" class="mode-button"></div>
    <div class="login-form">
      <form action="">
        <div v-show="loginMode === 'qrcode'" class="form-group" style="text-align: center">
          <img v-if="qrCodeUrl" style="width: 340px; height: 340px;" :src="qrCodeUrl">
        </div>
        <div v-show="loginMode !== 'qrcode'" class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                用户名
              </div>
            </div>
            <input v-model="username" class="form-control" type="text" placeholder="请填写用户名">
          </div>
        </div>
        <div v-show="loginMode !== 'qrcode'" class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                密<span class="text-transparent">一</span>码
              </div>
            </div>
            <input v-model="password" class="form-control" type="password" autocomplete="new-password" placeholder="请填写密码">
          </div>
        </div>
        <div v-show="loginMode !== 'qrcode'" class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">
                验证码
              </div>
            </div>
            <input v-model="code" class="form-control" type="text" placeholder="请填写验证码">
            <div class="input-group-append">
              <div class="input-group-text p-1" @click="handleCodeSrc">
                <img :src="codeSrc" alt="">
              </div>
            </div>
          </div>
        </div>
        <div v-show="loginMode !== 'qrcode'" class="form-group text-center">
          <button type="button" class="btn btn-primary mr-2" @click="handleLogin">登录</button>
          <button type="button" class="btn btn-secondary" @click="handleReset">重置</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import authService from '@/services/authService'
import auth from '@/common/auth'
import config from '@/config'
import utility from '@/common/utility'
import UserInfo from '@/support/UserInfo'
import axios from 'axios'

export default {
  name: 'Login',
  data () {
    return {
      loginMode: 'password',
      qrCodeKey: '',
      qrCodeUrl: '',
      username: '',
      password: '',
      sessionId: utility.getUuid(),
      code: '',
      swiperOption: {
        autoplay: {
          delay: 5000
        },
        loop: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        slideActiveClass: 'animation',
        observer: true,
        observeParent: true
      },
      swiperSlideStyle: {
        height: window.document.body.offsetHeight + 'px'
      },
      slides: [
        {
          id: 1,
          imageUrl: '/images/1.jpg'
        },
        {
          id: 2,
          imageUrl: '/images/2.jpg'
        },
        {
          id: 3,
          imageUrl: '/images/3.jpg'
        },
        {
          id: 4,
          imageUrl: '/images/4.jpg'
        },
        {
          id: 5,
          imageUrl: '/images/5.jpg'
        },
        {
          id: 6,
          imageUrl: '/images/6.jpg'
        }
      ]
    }
  },
  methods: {
    changeMode () {
      if (this.loginMode === 'qrcode') {
        this.loginMode = 'password'
      } else {
        this.loginMode = 'qrcode'
      }
    },
    handleLogin () {
      this._login()
    },
    handleReset () {
      this._reset()
    },
    handleCodeSrc () {
      this.sessionId = utility.getUuid()
    },
    _reset () {
      this.username = ''
      this.password = ''
      this.code = ''
      this.sessionId = utility.getUuid()
    },
    async getQrCode () {
      this.qrCodeKey = utility.getUuid()
      const response = await axios.get(`https://mp.ljkjkf.com/api/qrCode/qrCode?key=login@${this.qrCodeKey}`)
      console.log(response.data)
      this.qrCodeUrl = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${response.data.ticket}`
      console.log(this.qrCodeUrl)
    },
    async getToken () {
      try {
        const response = await axios.get(`http://meterage.ljkjkf.com:5035/wechatLoginInfo?key=${this.qrCodeKey}`)
        if (response.data.status === 1) {
          auth.setToken(response.data.token)

          authService.getUserInfo()
            .then(res => {
              console.log('userInfo', res.data)
              auth.setUserInfo(new UserInfo(res.data))

              document.onkeypress = null
              this.$router.push('/home')
            })
            .catch(err => {
              window.console.log(err.request.response)
            })
        } else {
          if (!auth.isLogin()) {
            setTimeout(() => {
              this.getToken()
            }, 1000)
          }
        }
      } catch (e) {
        if (!auth.isLogin()) {
          this.timeout = setTimeout(() => {
            this.getToken()
          }, 1000)
        }
      }
    },
    _login () {
      authService.login(this.username, this.password, this.sessionId, this.code)
        .then(res => {
          auth.setToken(res.data.access_token)

          authService.getUserInfo()
            .then(res => {
              console.log('userInfo', res.data)
              if (this.password === '888888') {
                this.$message({
                  duration: 6000,
                  type: 'warning',
                  message: '请您修改密码后再进行其它操作！'
                })
                res.data.poorPassword = true
              } else {
                res.data.poorPassword = false
              }
              auth.setUserInfo(new UserInfo(res.data))
              axios.get(`${config.serviceHost}/project/${auth.getUserInfo().projectGuid}`, {
                headers: {
                  Authorization: `Bearer ${auth.getToken()}`
                }
              }).then(projectResponse => {
                console.log(projectResponse.data, 'project data')
                auth.setUserInfo({
                  ...auth.getUserInfo(),
                  yearCount: projectResponse.data.data[0].yearCount
                })
                document.onkeypress = null
                this.$router.push('/home')
              })
            })
            .catch(err => {
              window.console.log(err.request.response)
            })
        })
        .catch(err => {
          console.log(err.request.response)
          this.$message({
            duration: 3000,
            type: 'error',
            message: JSON.parse(err.request.response).error_description ? JSON.parse(err.request.response).error_description : '登录失败！'
          })
        })
    }
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
  },
  computed: {
    codeSrc () {
      return `${config.authHost}/identity/${this.sessionId}`
    }
  },
  mounted () {
    this.getQrCode()
    this.getToken()
    console.log(this.swiperSlideStyle)
    document.onkeypress = (event) => {
      if (event.keyCode === 13) {
        this._login()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login-page {
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;
}
.login-form {
  position: absolute;
  top: 280px;
  left: 50%;
  width: 400px;
  padding: 50px 20px 0 20px;
  transform: translateX(-50%);
  border: 1px solid #aaa;
  border-radius: 10px;
  box-shadow: 0 0 10px #bbb;
  z-index: 999;
  background-color: rgba($color: #444, $alpha: .5)
}
.mode-button {
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  transform: translateX(-50%);
  left: calc(50% + 170px);
  top: 285px;
  background: rgba(#0a6332, .3);
  width: 40px;
  height: 40px;
  border-radius: 15%
}
.slogan {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
.slogan img {
  width: 600px;
  height: 100px;
}
.slide-image {
  width: 100%;
  height: 100%;
}
.animation .slide-image {
  animation: mymove 5s;
}
@keyframes mymove
{
  from {
    transform: scale3d(1.2, 1.2, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
img {
  width: 65px;
  height: 28px;
  cursor: pointer;
}
</style>
