import BaseService from '@/services/BaseService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'authority'

let treeListUrl = 'TreeList'
let treeListByUserUrl = 'GetListByVIEWUserGuidRoleGuid'

class AuthorityService extends BaseService {
  treeList () {
    return axios.get(utility.getFullUrl(this.resourceName, treeListUrl), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  treeListByUser () {
    return axios.get(utility.getFullUrl(this.resourceName, treeListByUserUrl), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const authorityService = new AuthorityService(resourceName)

export default authorityService
