<template>
  <div class="wechat container-fluid p-3">
    <router-view v-if="isLogin"></router-view>
  </div>
</template>

<script>
import authService from '@/services/authService'
import UserInfo from '@/support/UserInfo'
import auth from '@/common/auth'

export default {
  name: 'Wechat',
  data () {
    return {
      isLogin: false
    }
  },
  methods: {
    login () {
      this.$myLoading()
      const openId = this.$route.query.openid
      const appID = 0
      if (!openId) {
        this.$message({
          type: 'error',
          message: '认证信息不完整'
        })
        return
      }
      authService.login(`${openId}$2$${appID}`, '', '', '')
        .then(res => {
          auth.setToken(res.data.access_token)

          authService.getUserInfo()
            .then(res => {
              auth.setUserInfo(new UserInfo(res.data))

              this.$nextTick(() => {
                this.isLogin = true
                this.$loading().close()
              })
            })
            .catch(err => {
              console.log(err.request.response)
            })
        })
        .catch(err => {
          console.log(err.request.response)
          this.$message({
            type: 'error',
            message: JSON.parse(err.request.response).error_description ? JSON.parse(err.request.response).error_description : '登录失败！'
          })
        })
    }
  },
  created () {
    auth.logout()
    this.$nextTick(() => {
      this.login()
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/custom.scss';

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.wechat {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
