export default class UserInfo {
  constructor (props) {
    this.userGuid = props.UserGuid
    this.userName = props.UserName
    this.staffName = props.StaffName
    this.identityGuid = props.IdentityGuid
    this.tenderGuid = props.TenderGuid
    this.projectGuid = props.ProjectGuid
    this.companyGuid = props.CompanyGuid
    this.listLevelDisplay = props.ListLevelDisplay
    this.poorPassword = props.poorPassword
    this.yearCount = props.yearCount
  }
}
