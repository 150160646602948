import store from '@/store'

const auth = {

  getToken () {
    return store.getters['auth/getToken']
  },

  setToken (token) {
    store.commit('auth/setToken', token)
  },

  getUserInfo () {
    return store.getters['auth/getUserInfo']
  },

  setUserInfo (userInfo) {
    store.commit('auth/setUserInfo', userInfo)
  },

  isLogin () {
    return !!auth.getToken()
  },

  logout () {
    store.commit('auth/setToken', '')
    store.commit('auth/setUserInfo', null)
    store.commit('layout/clearBreadcrumbItems')
  }
}

export default auth
