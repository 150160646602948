<template>
  <div class="layout container-fluid">
    <my-menu></my-menu>
    <div class="content d-flex flex-column" :class="{ 'shrink' : !menuExtended }">
      <my-header></my-header>
      <div class="router-view-wrapper flex-fill overflow-auto mt-2 container-fluid">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MyMenu from '@/components/MyMenu/MyMenu'
import MyHeader from '@/components/MyHeader/MyHeader'

export default {
  name: 'App',
  components: {
    MyMenu,
    MyHeader
  },
  computed: {
    ...mapGetters('layout', {
      menuExtended: 'getMenuExtended'
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variable.scss';
@import '@/assets/scss/custom.scss';

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.layout {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
}
.content {
  position: absolute;
  left: $menu-extended-width;
  right: 0;
  top: 0;
  bottom: 0;
  transition: left .3s;
}
.content.shrink {
  left: $menu-shrinked-width;
}

.input-short {
  width: 200px;
}

.el-select {
  width: 100%;
}

.el-input-number {
  width: 100% !important;
}
</style>
