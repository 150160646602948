const state = {
  planStatExaminationGuid: '',
  tenderGuid: '',
  tenderName: '',
  year: 0,
  month: 0,
  date: 0,
  examinationCode: '',
  approvalUserGuid: '',
  examinationType: ''
}

const getters = {
  getPlanStatExaminationGuid: state => {
    return state.planStatExaminationGuid
  },
  getTenderGuid: state => {
    return state.tenderGuid
  },
  getTenderName: state => {
    return state.tenderName
  },
  getYear: state => {
    return state.year
  },
  getMonth: state => {
    return state.month
  },
  getDate: state => {
    return state.date
  },
  getExaminationCode: state => {
    return state.examinationCode
  },
  getApprovalUserGuid: state => {
    return state.approvalUserGuid
  },
  getExaminationType: state => {
    return state.examinationType
  }
}

const actions = {
}

const mutations = {
  setPlanStatExaminationGuid (state, planStatExaminationGuid) {
    state.planStatExaminationGuid = planStatExaminationGuid
  },
  setTenderGuid (state, tenderGuid) {
    state.tenderGuid = tenderGuid
  },
  setTenderName (state, tenderName) {
    state.tenderName = tenderName
  },
  setYear (state, year) {
    state.year = year
  },
  setMonth (state, month) {
    state.month = month
  },
  setDate (state, date) {
    state.date = date
  },
  setExaminationCode (state, examinationCode) {
    state.examinationCode = examinationCode
  },
  setApprovalUserGuid (state, approvalUserGuid) {
    state.approvalUserGuid = approvalUserGuid
  },
  setExaminationType (state, examinationType) {
    state.examinationType = examinationType
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
