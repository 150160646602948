<template>
  <div class="my-header">
    <nav class="navbar navbar-light bg-light border-bottom position-relative">
      <div class="d-flex align-items-center">
        <i class="fas fa-bars toggle-button text-muted ml-2 mr-3" @click="toggle"></i>
        <el-breadcrumb separator="/" class="nav ml-3">
          <template
            v-for="breadcrumbItem in breadcrumbItems"
            >
            <el-breadcrumb-item v-if="breadcrumbItem.active" :key="breadcrumbItem.path">
              <span class="breadcrumb-item">{{ breadcrumbItem.name }}</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item v-else :to="{ path: breadcrumbItem.path }" :key="breadcrumbItem.path">
              <span class="breadcrumb-item">{{ breadcrumbItem.name }}</span>
            </el-breadcrumb-item>
          </template>
        </el-breadcrumb>
        <div class="control-area position-absolute">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ staffName }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="change-password">修改密码</el-dropdown-item>
              <el-dropdown-item command="unbind-wechat" v-if="openId">解绑微信</el-dropdown-item>
              <el-dropdown-item command="bind-wechat" v-if="!openId">绑定微信</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <button class="btn" @click="handleLogout">
            <i class="el-icon-switch-button"></i>
          </button>
        </div>
      </div>
    </nav>
    <el-dialog
      :visible.sync="dialogShow"
      size="small"
      width="400px"
      title="请扫描二维码完成绑定">
      <div style="text-align: center">
        <img :src="qrCodeUrl" style="width: 320px; height: 320px;">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogShow = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import authService from '@/services/authService'
import auth from '@/common/auth'
import axios from 'axios'

export default {
  name: 'MyHeader',
  methods: {
    async handleCommand (command) {
      if (command === 'change-password') {
        this.changePassword()
      } else if (command === 'unbind-wechat') {
        this.$confirm('您确定要解绑微信吗？')
          .then(() => {
            axios.patch(`http://meterage.ljkjkf.com:5035/api/users/unbindOpenId/${auth.getUserInfo().userGuid}`).then(() => {
              this.$message.success('操作成功')
              this.openId = ''
            }).catch(() => {
              this.$message.error('操作失败')
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '您已取消操作！'
            })
          })
      } else if (command === 'bind-wechat') {
        const response = await axios.get(`https://mp.ljkjkf.com/api/qrCode/qrCode?key=bind@${auth.getUserInfo().userGuid}`)
        this.qrCodeUrl = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${response.data.ticket}`
        this.dialogShow = true
      }
      console.log(auth.getUserInfo())
    },
    changePassword () {
      this.$router.push('/passwordChange')
    },
    handleLogout () {
      this.$confirm('您确定要退出吗？')
        .then(() => {
          authService.logout()
          this.$router.push('/login')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    toggle () {
      this.setMenuExtended(!this.menuExtended)
    },
    async getOpenId () {
      const response = await axios.get(`http://meterage.ljkjkf.com:5035/api/users/${auth.getUserInfo().userGuid}`)
      this.openId = response.data.openID
    },
    ...mapMutations('layout', {
      setMenuExtended: 'setMenuExtended'
    })
  },
  data () {
    return {
      openId: '',
      dialogShow: false,
      qrCodeUrl: ''
    }
  },
  computed: {
    staffName () {
      return auth.getUserInfo().staffName
    },
    ...mapGetters('layout', {
      breadcrumbItems: 'getBreadcrumbItems',
      menuExtended: 'getMenuExtended'
    })
  },
  mounted () {
    this.getOpenId()
    console.log(this.breadcrumbItems)
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variable.scss';

nav {
  height: $header-height;
}
.nav {
  width: 100%;
}
.breadcrumb-item {
  font-size: 15px;
}

img {
  height: 25px;
  margin: 5px;
  cursor: pointer;
}
.control-area {
  right: 10px;
}

.toggle-button {
  cursor: pointer;
}
</style>
