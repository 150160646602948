const state = {
  token: '',
  userInfo: null
}

const getters = {
  getToken: state => {
    return state.token
  },
  getUserInfo: state => {
    return state.userInfo
  }
}

const actions = {
}

const mutations = {
  setToken (state, token) {
    state.token = token
  },
  setUserInfo (state, userInfo) {
    state.userInfo = userInfo
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
