import axios from 'axios'
import config from '@/config'
import utility from '@/common/utility'
import auth from '@/common/auth'

const authService = {

  login (username, password, sessionId, code) {
    return axios.post(config.authHost + config.tokenUrl, utility.getFormData({
      username: username,
      password: password,
      sessionId: sessionId,
      code: code,
      grant_type: config.grantType,
      client_id: config.clientId,
      client_secret: config.clientSecret
    }))
  },

  logout () {
    // TODO backend logout also needed
    auth.logout()
  },

  getUserInfo () {
    return axios.get(config.authHost + config.userInfoUrl, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

export default authService
