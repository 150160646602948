const config = {

  authHost: 'http://47.93.222.187:5000',
  // authHost: 'http://47.93.222.187:5005',
  // authHost: 'http://47.93.222.187:5005',
  // authHost: 'http://localhost:5000',
  // authHost: 'http://192.168.1.38:5000',

  serviceHost: 'http://47.93.222.187:5001',
  // serviceHost: 'http://47.93.222.187:5006',
  // serviceHost: 'http://47.93.222.187:5006',
  // serviceHost: 'http://localhost:5001',
  // serviceHost: 'http://192.168.1.38:5050'

  restHost: 'http://47.93.222.187:5035',
  // restHost: 'http://47.93.222.187:5037',
  // restHost: 'http://47.93.222.187:5037',
  // restHost: 'http://localhost:5035',
  // restHost: 'http://localhost:5037',
  // restHost: 'http://192.168.1.38:5035',

  reportHost: 'http://47.93.222.187:5002',
  // reportHost: 'http://47.93.222.187:5007',
  // reportHost: 'http://47.93.222.187:5002',
  // reportHost: 'http://47.93.222.187:5007',
  // reportHost: 'http://192.168.1.38:5052',

  tokenUrl: '/connect/token',

  userInfoUrl: '/connect/userinfo',

  clientId: 'client',

  clientSecret: 'secret',

  grantType: 'password',

  bigPageSize: 999999,

  noneGuid: '@',

  changeDirectiveDefaultGuid: 'default',

  fastReportKey: '87857892',

  applyIdentities: [
    '5D18AB10-508D-11EA-9058-CBFD780DAEB0'
  ],

  sumAmountIdentity: 'DD511FC7-AE1B-4BAB-8957-F3B2B7D542EC',

  finalSubmitIdentities: [
    'DD511FC7-AE1B-4BAB-8957-F3B2B7D542EC',
    'BA4E9900-508F-11EA-94EF-0B27DB64FF55',
    '97091390-82d9-11ea-9829-07fcd5e3bad3'
  ],

  proIdentities: [
    'B4372B21-51B1-4A9A-9ECC-3459177EAAB4'
  ],

  superIdenties: [
    '94959c90-b230-11ec-9c08-51ca407fac54'
  ],

  labIdentities: [
    '6D5F89D0-508D-11EA-9058-CBFD780DAEB0',
    'b627ac00-cdb5-11eb-8fd6-8b331cba65e7'
  ],

  meterageListOverflowCode: 'overflow',

  reportFolder: 'Upload',

  monthStart: 16,

  projectStartYear: {
    '4204E380-4FAB-11EA-8A9D-27BD65D6829C': 2020
  },

  planStatSuperviseTenderGuids: [
    '7bd7d8e0-8ffb-11ed-b261-85a0b54030f5',
    '7f9a90c0-8ffc-11ed-b261-85a0b54030f5',
    '0d2e7640-8ffd-11ed-b261-85a0b54030f5',
    'ad1202d0-8ffd-11ed-b261-85a0b54030f5',
    '2c27bba0-8ffe-11ed-b261-85a0b54030f5'
  ]
}

export default config
